
.gridButton{
    padding: 0px !important;
}

.icongrid{
    margin-left: 15px !important;
}

.MuiDataGrid-root
{
    color: #fff !important;
    font-size: 14px !important;
}

.MuiTablePagination-root
{
    color: #fff !important;
    font-size: 14px !important;
}

.btnAgregar
{
   background-color:#50B09E!important;
   padding-left: 20px !important;
   margin-left: 60px !important;
}

.systemTitle{
    text-align: center !important;
    color: white;
}

.grid-Clientes button.btnAgregar
{
   background-color:#50B09E!important;
}

.even-row {
    background-color: #272B34; 
}
  
.odd-row {
background-color: #272B34;
}

.MuiDataGrid-row:hover
{
    background-color: #7C7877 !important;
}

.icon-with-text {
    position: fixed;
    display: inline-block;
}
.icon-with-text:hover::after {
    content: attr(data-text);
    transform: translateX(-50%);
    background-color: black;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
}

.buttonSD{    
    background-color: #272B34;
    color: white;
    border: 2px solid #272B34;
    border-radius: 5px;
    cursor: pointer;
    width: 40px;
    height: 35px;
  }