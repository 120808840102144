.reset-ext {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 672px;
    flex-direction: column;
    margin-top: 200px;
}

.reset-int {
    text-align: center;
    width: 900px;
    height: 504px;
}

.reset-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.reset-izq{
    height: 200px;
    width: 30%;
    text-align: right;
    color: white;
}

.reset-der{
    height: 200px;
    width: 50%;
    text-align: left;
}

.reset-txt{
    margin-top: 10px;
    margin-left: 10px;
}


.btn-send-code{
    background-color: #50B09E;
    color: white;
    border: 2px solid #50B09E;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 90px;
    height: 32px;
    margin-left: 10px;
}