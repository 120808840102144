.info-cliente
{
    margin: 15px 0;
    background-color: #363A45;
    color: #fff !important;
    font-family: sans-serif,'Roboto';
    height: 700px;
}

.inputBox {
    height: 30px;
    width: 350px;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
  }

.txtLabel{
    margin-bottom: 5px;
}

.MuiTab-root{
    color: #fff !important;
    font-family: sans-serif,'Roboto' !important;
}

.MuiTabs-indicator {
    background-color: #50B09E !important; /* Color del indicador cuando el tab está activo */
  }
  
  .Mui-selected {
    color: #50B09E !important; /* Color del texto cuando el tab está activo */
  }

.client-form .btnForm button
{
    color: #fff !important;
    font-size: 14px;
    margin: 5px;
}

.client-form .btnForm
{
    width: 97%;
    height: 20%;
    position: absolute;
    top:700px;
    padding: 0 24px;
}

.client-form .contenedorPrincipal
{
    width: 97%;
    height: 600px;
}

.client-form .contenedor1
{
    float:left;
    width: 25%;
    margin-bottom: 25px;
    height: 600px;
}

.client-form .contenedor2
{
    float: right;
    width: 75%;
    margin-bottom: 25px;
    height: 600px;
}

.client-form .btnForm button.MuiButtonBase-root
{
   background-color:#50B09E;
}

.client-form .btnForm .MuiOutlinedInput-root {
    height: 30px !important;
}

.client-form .btnForm button.MuiButton-root:hover{
    background-color: none!important;
}

.client-form .btnForm button.MuiButtonBase-root
{
   background-color:#50B09E;
   float: left;
}

.client-form .btnForm .MuiOutlinedInput-root {
    height: 30px !important;
}

.btnForm button.MuiButton-root:hover{
    background-color: #559287!important;
}

.client-form .btnForm button
{
    color: #fff !important;
    font-size: 14px;
    margin: 5px;
}

.Mui-disabled{
    -webkit-text-fill-color: white !important;
}


.info-cliente button.MuiTablePagination-actions
{
    color:#ffffff!important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0px !important;
}