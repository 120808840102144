.container {
    max-width: auto;
    margin: 0 auto;
    padding-left: 200px;
    padding-right: 200px;
}

.container-gpc{
    padding: 20px;
}

.enlace{
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.logo{
    height: 80px;
}

.indicatorText{
    float: left;
    margin-top: 25px;
    margin-left: 20px;
    color: white;
    font-size: 20px;
}

.checkbtn{
    font-size: 30px;
    color: #07171A;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}

#check{
    display: none;
}

@media (max-width: 952px){
    .enlace{
        padding-left: 20px;
    }
    nav ul li a{
        font-size: 16px;
    }
}

@media (max-width: 858px){
    .checkbtn{
        display: block;
    }
    ul{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #2c3e50;
        top: 80px;
        left: -100%;
        text-align: center;
        transition: all .5s;
    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }
    nav ul li a{
        font-size: 20px;
    }
    li a:hover, li a.active{
        background: none;
        color: red;
    }
    #check:checked ~ ul{
        left:0;
    }
}

  .tarjeta{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:220px;
    height: 250PX;
    border: 1px solid #07171A;
    border-radius:15px;
    font-family: "Medium";
  }

  .titulo{
    font-size: 24px;
    padding: 10px 10px 0 10px;;
    color: #07171A;
  }

  .cuerpo{
    padding: 10px;
    color: #07171A;
  }

  .pie{   
    background: #97c93f;
    border-radius:0 0 15px 15px;
    padding: 10px;
    text-align:center;
  }

  .pie a{
    text-decoration: none;
    color: #07171A;
  }

  .pie a:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
  }