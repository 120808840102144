.fechasgroup{
    float: right;
    padding-top: 20px;
}

.calendar{
    display:inline-block;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-button {
    margin-right: auto;
  }

  .button {
    width: 300px;
    border: '1px solid #5672B3' !important;

    /* color: white !important; */
  }
  
  .custom-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: #333;
    margin: 0px;
  }

  .custom-tooltip p {
    margin: 5px 0; /* Ajusta el margen vertical entre los párrafos */
  }

  .MuiStack-root{
    padding-top: 0px !important;
  }

  .chart-container {
    width: 100%;
    height: 100%;
  }

  .indicator-poliza {
    padding-top: 20px;
  }

  .button-icon{
    width: 10px !important;
  }


  .MuiButton-root{
    min-width: 0px !important;
  }

  .MuiSelect-select{
    padding: 4px !important;
    color: #fff !important;
  }


  /* EnergyFlow style */

.ef-center-ele{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ef-center-spa{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.ef-center-lines{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.ef-img-car{
    padding-top: 20px;
}

.ef-img-gen{
  padding-bottom: 30px;
}

.ef-img-solar{
  padding-top: 30px;
  padding-left: 30px;
}


@keyframes recorridoHaciaDerecha {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 10px 0; /* Espacio entre segmentos */
  }
}

@keyframes recorridoHaciaAbajo {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 10px; /* Espacio entre segmentos (en el eje Y) */
  }
}

.linea-fija {
  margin-top: 60px;
  width: 100%;
  height: 2px; /* Altura de la línea */
  background-image: linear-gradient(90deg, #363a45 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 10px; /* Tamaño de cada segmento y espacio entre segmentos */

}

.linea-vertical {
    align-items: center;
    width: 2px;
    height: 100%;
    background: linear-gradient(0deg, #363a45 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
    background-size: 2px 10px;
  }

.linea-discontinua2 {
  margin-top: 60px;
  width: 100%;
  height: 2px; /* Altura de la línea */
  background-color: transparent;
  background-image: linear-gradient(90deg, #50B09E 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 10px 2px; /* Tamaño de cada segmento y espacio entre segmentos */
  animation: recorridoHaciaDerecha 2s linear infinite;
}

.linea-discontinua {
  align-items: center;
  width: 2px; /* Ancho de la línea */
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(0deg, #50B09E 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 2px 10px; /* Tamaño de cada segmento y espacio entre segmentos (en el eje Y) */
  animation: recorridoHaciaAbajo 1s linear infinite;
}

.full-lineas{
  height: 120px;
  width: 90px;
}

.alto-lineas{
  height: 120px;
  /* margin: 0 13%; */
}

.linea-diagonal-i-s {
  align-items: center;
  width: 2px;
  height: 100%;
  background: linear-gradient(0deg, #363a45 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 2px 10px;
  transform: rotate(45deg);
}


.linea-diagonal-i {
  align-items: center;
  width: 2px;
  height: 100%;
  background: linear-gradient(0deg, #50B09E 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 2px 10px;
  transform: rotate(45deg);
  animation: recorridoHaciaAbajo 1s linear infinite;
}

.linea-diagonal-d {
  align-items: center;
  width: 2px;
  height: 100%;
  background: linear-gradient(0deg, #363a45 65%, transparent 25%); /* Color de la línea y espacio entre segmentos */
  background-size: 2px 10px;
  transform: rotate(135deg);
}


@keyframes recorridoHaciaAbajo5 {
  0% {
    transform: translateY(0); /* Inicio en posición 0 en el eje Y */
  }
  100% {
    transform: translateY(50px); /* Desplazamiento hacia abajo de 50 píxeles */
  }
}




.blobs-container {
	display: flex;
}

.blob {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	margin: 10px;
	height: 50px;
	width: 50px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.blob.white {
	background: white;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.question {
  width: 500px;
  height: 300px;
}

.question2 {
  width: 1300px;
  height: 500px;
}

.question-container {
  width: 100%;
  height: 100%;
}