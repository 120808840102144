.containerMessage {
    display: flex;

    height: 75vh;
  }
  
  .formMessage {
    color: white;
    font-size: x-large;
    text-align: center;
  }

  .footerMessage{
    margin-top: 100px;
  }

  .formsGeneral {
    display: flex;
    justify-content: center;
    align-items: center;   
    margin-bottom: 50px;
  }
  
  .centeredForm {
    width: 30%; /* o cualquier otro ancho deseado */
    border: 2px solid #5ebdd9;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding-left: 8%;
    padding-bottom: 20px;
  }

  .inputTopMar{
    margin-top: 10px !important;
  }