.container-charts {
    width: 80%;
    height: 100%;
    text-align: center;
    padding: 20px;
  }


  .body2 {
    margin: 0 40px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 733px;
  }