.navbar {
    display: flex;
    align-items: center;
    opacity: 0.8;
    height: 10px;
    top: 0px;
    padding: 0 50px;
    padding-top: 15px;
  }

.nuna-logo{
  height: 70px;
  margin: 10px;
  padding-left: 70px;
  padding-top: 60px;
}

.navbar h2
{
    width: 101px;
    font-size: 16px;
    color: white;
    height: 10px;
    position: absolute;
    left: 205px;
    top: 40px;
}

.navbar a {
    color: #fff;
    font-size: 16px;
 }
 .separador
 {
   margin-left: 10px;
   font-size: 30px;
 }
  
  li {
    list-style-type: none;
  }

a {
    text-decoration: none;
}

nav ul{
    float: left;
    margin-top: 70px;
}

nav ul li{
    display: inline-block;
    line-height: 20px;
    margin: 0 5px;
    margin-top: 20px;
}

li a.active, li a:hover{
    color:#50B09E;
    transition: .5s;
}

.navbar .MuiSvgIcon-root{
    position: absolute;
    left: 230px;
    top: 45px;
    color:#fff;
}