.grid-Alarmas
{
    padding-top:25px;
    float: left;
    width: 95%;
    /*padding-left:34px;
    padding-right:34px;*/
    font-family: sans-serif,'Roboto';
    color: #fff
}

.gridButton{
    padding: 0px !important;
}

.icongrid{
    margin-left: 15px !important;
}

.grid-Alarmas h1
{
    padding-left: 60px;
    font-size: 22px;
}

.MuiDataGrid-root
{
    color: #fff !important;
    font-size: 14px !important;
}

.MuiTablePagination-root
{
    color: #fff !important;
    font-size: 14px !important;
}

.grid-Alarmas .MuiSvgIcon-root {
    color: #ffffff !important;
}

.grid-Alarmas button.btnAgregar
{
   background-color:#50B09E!important;
}

.grid-Alarmas button.gridButton
{
   background-color:#50B09E!important;
}

.grid-Alarmas .btnAgregar{
    margin-left:15px;
}

.grid-Alarmas button.btnAgregar:hover{
    background-color: #559287!important;
}

.grid-Alarmas button.gridButton:hover{
    background-color: #559287!important;
}
