@font-face {
  font-family: 'ITC Avant Garde Gothic Std Book';
  src: url('../fonts/ITC_Avant_Garde_Gothic_Std_Book.otf');
}
body{
  font-family:  'ITC Avant Garde Gothic Std Book' !important;
}
.css-h8zb4y{
  background-color: transparent !important;
}


  .contImg{
    height: 758px;
  }
  .back-img{
    background-image: url('../imgs/Mano_imagen.jpg');
    background-position: center;
    background-size: cover;
    height: 758px;
  }

  .div2part {
    display: flex;
  }
  .parte1{
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: white;
  }
  .parte2 {
    flex: 1; 
  }

  .parte2-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 701px;
    width:50%;
  }

  .happy-img{
    background-image: url('../imgs/mobile1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .nuna-img{
    background-image: url('../imgs/Isla_Tortuga.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .big-img{
    background-image: url('../imgs/big_picture.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .intro-ext {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 672px; /* Esto es opcional, solo para propósitos de visualización */
  }
  
  .intro-int {
    color: white;
    height: 432px;
    width: 1050px;
  }

  .intro-box{
    text-align: center;
    width: 301px;
    height: 204px;
  }

  .generic-text-center{
    text-align: center;
    font-size: x-large;
  }

  .generic-title-center{
    font-size:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }

  .generic-title-just{
    font-size:40px;
    height: 48px;
    display: block;
    align-items: flex-start;
  }

  .generic-subtitle-green{
    font-size:20px;
    font-weight: bold;
    color: #5ebdd9;
    margin: 0;
    padding: 0;
  }

  .generic-text-just{
    font-size: x-large;
  }

  .highlights-ext {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 774px; /* Esto es opcional, solo para propósitos de visualización */
    
  }
  
  .highlights-int {
    color: white;
    height: 577px;
    width: 1030px;
  }

  .footer-ext {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 425px; /* Esto es opcional, solo para propósitos de visualización */
    background-color: #272B34; /* Solo para propósitos de visualización */
  }
  
  .footer-int {
    height: 220px;
    width: 1240px;
    color: white;
    display: flex !important;  
  }

  .alianzas-ext {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 474px; /* Esto es opcional, solo para propósitos de visualización */
    background-color: #272B34; /* Solo para propósitos de visualización */
  }
  
  .alianzas-int {
    height: 365px;
    width: 1000px;    
    color: white;
  }

  .nunah35{
    height: 35px;  
  }
  .nunah25{
    height: 25px;
  }
  .nunah15{
    height: 20px;
  }
  .nunahpeq{
    height: 80px;
  }
  .nunahgra{
    height: 250px;
  }
  .texto-destacado {
    color: #5ebdd9;
  }

  .texto-destacado2 {
    color: #5ebdd9;
  }

  .divImg{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divText{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contAlianzas{
    display: flex;
    justify-content: center; /* Alineación horizontal centrada */
    align-items: center;
    opacity: 0.5;
    column-gap: 60px;
  }

  .imgAlianzas {
    margin: 10px;
  }
  .logoAcon{
    height: 34px;
  }
  .logoMarriot{
    height: 100px;
  }
  .logoPurdy{
    height: 44px;
  }
  .logoAuto{
    height: 69px;
  }
  .logoConfig{
    height: 63px;
    width: 63px;
  }
.iconsfooter{
    height: 40px;
    margin-right: 15px;
  }

  .iconsfooter2{
    height: 30px;
    margin-right: 15px;
  }

  .linea {
    height: 1px;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(to right, #FFFFFF 100%, transparent 50%); /* Gradiente para simular la línea delgada */
    background-size: 1px 1px; /* Tamaño del gradiente */
  }

  .linea-h {
    width: 100%; /* Ancho total de la línea */
    height: 1px; /* Grosor de la línea */
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0; /* Sin relleno */
    background-image: linear-gradient(to right, #FFFFFF 100%, transparent 50%); /* Gradiente horizontal */
    background-size: 1px 1px; /* Tamaño del gradiente */
  }

  .divText-top{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .generic-title-footer{
    font-size:40px;
    height: 48px;
    margin: 0
  }

  .footer-box{
    text-align: center;
    width: 954px;
  }

  .top-superpuesto {
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-superpuesto-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }

  .header-logo{
    margin-left: 100px;
  }

  .header-buttons{
    margin-right:5%;
    margin-left: auto;
    display: flex;
    column-gap: 30px;
  }

  .buttonsStyle{
    background-color: transparent;
    color: #5ebdd9;
    border: 2px solid #5ebdd9;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 190px;
    height: 44px;
  }

  .buttonSl{    
    background-color: #5ebdd9;
    color: white;
    border: 2px solid #5ebdd9;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 240px;
    height: 44px;
  }

  .generic-text-just-xl{
    font-size: 32px;
    color: white;
  }

  .vamo{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inpLogin{

  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  font-size: large;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 9999; /* Asegura que el modal esté en la parte superior */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    position: absolute;
    top: 25%;
    left:82%;
    transform: translate(-30%, -30%);
    background-color: white;
    padding: 20px;
    width: 300px;
    background: #272B34;
    border-radius: 8px;
  }

  .terms-div {
    font-size: larger;
    max-width: 1200px;
    color: white;
  }

.terms-ext-div{
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
}
