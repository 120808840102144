.header {
    height: 105px;
    width: 100%;
    font-family: sans-serif,'Roboto';
    color: white;
    align-content: center;
    float: left;
    background-color: #363A45;
 }

 .nav-icon{
    float: right;
    padding-right: 100px;
    position: relative;
    top: -50px;
 }

 .nav-icon button{
    right: 10px;
 }

 .btn-alarm
{
    position: relative;
    top: 64px;
    right: 50px;
    width: 40px;
}

.header .MuiSvgIcon-root {
    color: #50B09E !important;
}
 

span.css-jcn4dz-MuiBadge-badge
{
    background-color: #df6e53 !important;
}

.LinkMas
{
    position: relative;
    float: right;
    margin: 10px 30px;
}



  
