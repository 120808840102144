.user-ext {
    display: flex;
    align-items: center;
    width: 100%;
    height: 765px;
    flex-direction: column;
}

.user-int {
    color: white;
    height: 432px;
}

.btnNewUser{  
    background-color: #50B09E;
    color: white;
    border: 2px solid #50B09E;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 120px;
    height: 40px;      
    margin: 30px 0px 30px 0px;
}

.form-ext {
    display: flex;
    align-items: center;
    width: 100%;
    height: 745px;
    flex-direction: column;
}

.form-int {
    color: white;
    height: 600px;
}

.formUser-btn {
    margin-right: 20px;
}