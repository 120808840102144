.button-grid {
  font-family: 'sans-serif' !important;
  color: #fff !important; /* Color del texto del botón */
  font-size: 14px !important;
  text-align: center !important;
  min-width: 15px !important;
  margin-left: 20px !important;
  text-transform: none !important;
}

.graph-text{
  font-family: sans-serif !important;
  color: #fff !important; /* Color del texto del botón */
  font-size: 14px !important;
  text-align: center !important;
  text-transform: none !important;
}

.MuiButton-root:hover{
  background-color: transparent !important;
}

.Mui-selected{

  color: #82ca9d !important;
}

.separador-graph{
  color: #fff;
  padding-top: 15px;
}

.central-indicators{
  padding-top: 0px !important;
}


.graph-Body{
  font-family: sans-serif !important;
  color: #fff !important; /* Color del texto del botón */
  text-transform: none !important;
  font-size: 25px !important;
  margin: 0px !important;
}


.indicator-alert{
  color: #CF6679 !important;
  font-family: sans-serif !important;
  text-transform: none !important;
  margin: 0px !important;
}

.indicator-mess{
  color: white !important;
  font-family: sans-serif !important;
  text-transform: none !important;
  margin: 0px !important;
} 

#map img
{
    max-width : none !important; 
}

.MuiTabPanel-root{
  padding-top: 0px !important;
}

.MuiOutlinedInput-input{
  color: white !important;
  padding: 4px !important;
  margin-left: 15px !important;
}

.MuiOutlinedInput-root{
  border: 1px solid #5672B3;
}

.MuiSelect-icon{
  color: #5672B3 !important;
}

.MuiSvgIcon-root{
  color: #5672B3 !important;
}

.MuiStack-root{
  overflow: hidden !important;
}