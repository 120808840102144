.footer {
    background-color: #363A45;
    height: 80px;
    font-family: sans-serif,'Roboto';
    font-size: 14px;
    color: white;
    display: flex;
    /* position: absolute;
    bottom: 0;
    width: 100%; */
  }

  .footer-bot {
    background-color: #363A45;
    height: 80px;
    font-family: sans-serif,'Roboto';
    font-size: 14px;
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .footer p {
    color: #fff;
    margin-top: 40px;
    padding-left: 5%;
    width: auto;
  }

  .infoFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin-top: 0px !important;
    flex-direction: column;
    color: white;
  }

  .infoFooterImg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin-top: 0px !important;
  }

  .footerImgC{
    width: 30%;
  }

  .footerCTxt{
    margin-right: 20px;
  }